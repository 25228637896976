import React, { useState } from "react";
import { Link } from "react-router-dom";
import { loginUser } from "../../controller/userAuth.ts";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from "../Login/login.module.css";

function LoginPage() {
  const navigate = useNavigate();

  const [formValues, setFormValues] = useState({
    phonenumber: '',
    password: ''
  });

  const [loading, setLoading] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true); // Show loader

    try {
      var isUserAuth = await loginUser(formValues);
      console.log(isUserAuth);
      if(isUserAuth.toString().includes("Subscription Expried") ) {
        const phoneNumber = isUserAuth.match(/\d+/)?.[0] || "";
        localStorage.setItem("userName", phoneNumber)
        navigate("/buynow");
      }
      else if(isUserAuth === "You are not a registered user")
      {
        navigate("/signUp");
      }
      else if (isUserAuth === "invalid credentials"){
        toast.error('Invalid credentials', { autoClose: 2000 });
      }
      else if (isUserAuth.toString().length === 10)
      {
        localStorage.setItem("userName", isUserAuth)
        navigate("/gitaMentor");
      }
      else
      {
        toast.error('Invalid credentials', { autoClose: 2000 });
      }
    } catch (error) {
      console.log(error);
      toast.error('An error occurred', { autoClose: 2000 });
    } finally {
      setLoading(false); // Hide loader
    }
  };

  return (
    <>
      <ToastContainer />
      <div className={styles.loginContainer}>
        <div className={styles.loginBox}>
          <div className={styles.imageContainer}>

          </div>
          <div className={styles.formContainer}>
            <p className={styles.loginTitle}>Login Page</p>
            <form onSubmit={handleSubmit} className={styles.loginForm}>
              <label htmlFor="phonenumber">Phone Number:</label>
              <input
                type="tel"
                name="phonenumber"
                pattern="[0-9]{10}"
                value={formValues.phonenumber}
                onChange={handleInputChange}
                className={styles.inputField}
              />

              <label htmlFor="password">Password:</label>
              <input
                type="password"
                name="password"
                value={formValues.password}
                onChange={handleInputChange}
                className={styles.inputField}
              />

              {loading ? (
                <div className={styles.loader}></div>
              ) : (
                <button type="submit" className={styles.loginButton}>Login</button>
              )}
            </form>
            <div className={styles.signupLink}>
              <Link to="/signUp" className={styles.signupButton}>
                Sign Up
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginPage;
