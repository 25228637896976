import React, { useState,useRef,useEffect  } from "react";
import styles from "./gita.module.css";
// import "./controller/dbcontroller.ts"
import Loader from "../../Loader.js";
import { gitaAPi } from "../../controller/dbcontroller.ts";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function GitaPage() {
  const [inputValue, setInputValue] = useState("");
  const [reponsevalue, setResponseValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const navigate = useNavigate();

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleButtonClick = async () => {
    setTheResponse("");
    setLoading(true);
    console.log("button clicked -> ", inputValue);
    var userData = localStorage.getItem("userName")
    var apiResposnse = await gitaAPi(inputValue,userData);
    if(apiResposnse === "invalid Subscription") {
      toast.error("Subscription has expired");
      navigate("/buynow");
    }
    else{
      setTheResponse(apiResposnse);
    }
    setLoading(false);
  };



  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleButtonClick();
    }
  };

  const setTheResponse = (resValue) => {
    setResponseValue(resValue);
  };
  const handleBackClick = async () => {
    navigate("/");
  };

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };


  const handleClickOutsideMenu = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };

  const handleprivacyClick = (event) => {
    navigate("/privacypolicy");
  };
  const handleTermsClick = (event) =>{
    navigate("/termsandcondition");
  }
  const handleCancellationClick = (event) =>{
    navigate("/cancellationpolicy");
  }
  const handleContactUsClick = (event) =>{
    navigate("/contactUs");
  }
  const handlebuyNowClick = (event) =>{
    navigate("/buynow");
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideMenu);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideMenu);
    };
  }, []);

  return (
    <section className={styles.bibleSection}>
      <ToastContainer/>      
      <div className={styles.container}>
        <p className={styles.topContent}>
          'Those who are constantly devoted to serving me with love, I give the
          understanding by which they can come to me.'
        </p>
        <p className={styles.topContentTwo}>- Bhagavad Gita 10.10</p>
        <div className={styles.inputContainer}>
          <h3>Instructions</h3>
          <p>
            Type your <strong>Question</strong> and click on{" "}
            <strong>Submit</strong>
          </p>
          <aside>
            <p>Note: All your answers will be based on the Bhagavad Gita.</p>
          </aside>
        </div>
        <div className={styles.inputContainer}>
          <input type="text" value={inputValue} onChange={handleInputChange} onKeyDown={handleKeyDown} />
          <div className={styles.submitButtonDiv} >
            <button className={styles.submitButton} 
            onClick={handleButtonClick} 
            >
              Submit
            </button>
          </div>
        </div>
        <div className={styles.displayContainer}>
          <h3>Answer</h3>
          <div className={styles.displayBox}>
            <div className="loader-wrapper">{loading && <Loader />}</div>
            {reponsevalue && (
              <div className={styles.responseCont}>{reponsevalue}</div>
            )}
          </div>
        </div>
      </div>
      <div className={styles.menuButton} onClick={handleMenuToggle}>
        <div className={styles.menuIcon}></div>
      </div>
      {isMenuOpen && (
        <div className={styles.menu} ref={menuRef}>
          <p onClick={handleprivacyClick}>Privacy Policy</p>
          <p onClick={handleTermsClick}>Terms and Conditions</p>
          <p onClick={handleCancellationClick}>Cancellation & Refund Policy</p>
          <p onClick={handleContactUsClick}>Contact Us</p>
          <p onClick={handlebuyNowClick}>Subscription</p>
          <p onClick={handleBackClick}>Logout</p>
        </div>
      )}
    </section>
  );
}

export default GitaPage;
