import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { genOtp, verifyOtp, createUser } from "../../controller/userSignUp.ts";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "./signup.module.css";

function SignupPage() {
  const navigate = useNavigate();

  const [genOtpbool, updateGenerateOtpClicked] = useState(false);
  const [formValues, setFormValues] = useState({
    name: "",
    age: "",
    phonenumber: "",
    otp: "",
    password: "",
  });
  const [isOtpVerified, setIsOtpVerified] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let passwordError = "";

    if (name === "password") {
      // Password validation regex
      const passwordRegex =
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
      if (!passwordRegex.test(value)) {
        passwordError =
          "Password must contain at least 8 characters including letters, numbers, and symbols.";
      }
    }

    setFormValues({ ...formValues, [name]: value, passwordError });
  };

  const genOtpClicked = (event) => {
    event.preventDefault();
    genOtp(formValues.phonenumber.toString());
    toast.success("OTP sent to your mobile number", { autoClose: 2000 });
    updateGenerateOtpClicked(true);
  };

  const validateOtpClicked = async (event) => {
    event.preventDefault();
    const isOtpVerifiedres = await verifyOtp(formValues);
    if (isOtpVerifiedres === "True") {
      setIsOtpVerified(true);
      toast.success("OTP verified successfully!", { autoClose: 2000 });
    } else {
      setIsOtpVerified(false);
      toast.error("Please verify your OTP to sign up.", { autoClose: 2000 });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (isOtpVerified) {
      const response = await createUser(formValues);
      console.log(response);
      if (response === "success") {
        navigate("/");
      }
      else if (response === "Phone number already exists") {
        toast.error(response, {
          autoClose: 2000,
        });
      }
    }
    
     else {
      toast.warn("Please verify your OTP before submitting the form.", {
        autoClose: 2000,
      });
    }
  };

  return (
    <section className={styles.backImage}>
<div className={styles.signupContainer}>
      <div className={styles.signupBox}>
        <div className={styles.imageContainer}></div>
        <div className={styles.formContainer}>
          <ToastContainer />
          <p className={styles.signupTitle}>Sign Up</p>
          <form onSubmit={handleSubmit} className={styles.signupForm}>
            <label htmlFor="name">Your name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formValues.name}
              onChange={handleInputChange}
              required
            />

            <label htmlFor="age">Age</label>
            <input
              type="number"
              id="age"
              name="age"
              value={formValues.age}
              onChange={handleInputChange}
              required
            />

            <label htmlFor="phonenumber">Phonenumber</label>
            <div className={styles.phoneInput}>
              <input
                type="tel"
                id="phonenumber"
                name="phonenumber"
                value={formValues.phonenumber}
                onChange={handleInputChange}
                required
              />

              <button onClick={genOtpClicked}>Generate OTP</button>
            </div>

            {genOtpbool && (
              <>
                <label htmlFor="otp">Verify OTP</label>
                <div className={styles.otpInput}>
                  <input
                    type="number"
                    id="otp"
                    name="otp"
                    value={formValues.otp}
                    onChange={handleInputChange}
                    required
                  />
                  {isOtpVerified && (
                    <span className={styles.tickSymbol}>✔</span>
                  )}

                  {isOtpVerified ? (
                    ""
                  ) : (
                    <button onClick={validateOtpClicked}>Verify OTP</button>
                  )}
                </div>
              </>
            )}

            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              name="password"
              value={formValues.password}
              onChange={handleInputChange}
              required
            />
            {formValues.passwordError && (
              <div className={styles.error}>{formValues.passwordError}</div>
            )}

            <input
              type="submit"
              value="Submit"
              className={styles.signupButton}
              // disabled={!isOtpVerified}
            />
          </form>
        </div>
      </div>
    </div>
    </section>
    
  );
}

export default SignupPage;
